import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Helmet } from "react-helmet"

import "./header.css"

const Header = ({ data, slug }) => {

  const siteMetadata = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `
  )

  // setup BreadcrumbsList based on data.breadcrumbs
  const breadcrumbs = data.breadcrumbs.map((breadcrumb, key) => ({
    "@type": "ListItem",
    position: key + 1,
    name: breadcrumb.title,
    item: siteMetadata.site.siteMetadata.siteUrl + breadcrumb.url
  }));

  // Add curent page element to BreadcrumbsList
  breadcrumbs.push({
    "@type": "ListItem",
    position: breadcrumbs.length + 1,
    name: data.title,
    // item: window.location.href
  });

  const BreadcrumbsList = {
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    itemListElement: breadcrumbs
  };

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(BreadcrumbsList)}
        </script>
      </Helmet>

      <div className={`page-header ${slug}-header ${data.overlay ? 'overlay' : '' }`}>

        <div className="header-info-container">
          <h1 className="page-title">{data.title}</h1>
          <ul id="breadcrumbs">
            {data.breadcrumbs &&
              data.breadcrumbs.map((breadcrumb) => (
              <li><Link to={breadcrumb.url}>{breadcrumb.title}</Link></li>
            ))}
            <li className="active">{data.title}</li>
          </ul>
        </div>

        <div className="header-img-container">
          <GatsbyImage
            image={data.image.childImageSharp.gatsbyImageData}
            alt={`${data.title} header`}
          />
        </div>

      </div>
    </>

  )
}

export default Header